import "./formComponent";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import Flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { Polish } from "flatpickr/dist/l10n/pl.js";

//helper to select width
function adjustSelectWidth(e) {
    // assuming something is always selected, please test!
    const displayedText = e.options[e.selectedIndex].innerText;
    const dummy = document.createElement("div");
    dummy.innerText = displayedText;
    dummy.style.position = "absolute";
    dummy.style.visibility = "hidden";
    document.body.insertBefore(dummy, document.body.firstChild);
    const measuredWidth = dummy.clientWidth;
    document.body.removeChild(dummy);
    e.style.width = (measuredWidth + 30) * 1.4 + "px";
}

window.calendar = {
    calendarHandler: "",
    setLocale: () => {
        const locales = document.documentElement.dataset.locale;
        if (locales === "de") {
            return Dutch;
        } else if (locales === "fr") {
            return French;
        } else if (locales === "pl") {
            return Polish;
        } else {
            return false;
        }
    },

    windowsCalendarHoverAnimation() {
        const light = document.createElement("div"),
            area = document.querySelector(".flatpickr-innerContainer"),
            dayCont = document.querySelector(".flatpickr-innerContainer");

        light.id = "lightbulb";
        dayCont.appendChild(light);
        area.addEventListener("mousemove", (e) => {
            var targetCoords = area.getBoundingClientRect();
            light.style.left = e.clientX - targetCoords.left + "px";
            light.style.top = e.clientY - targetCoords.top + "px";
        });

        function removeLightOnMob() {
            if (
                window.matchMedia("(max-width: 1023px)").matches ||
                document.body.classList.contains("touch")
            ) {
                light.remove();
            }
        }
        removeLightOnMob();

        window.addEventListener("resize", removeLightOnMob);
    },

    calendar() {
        let disDates =
            typeof disableDate !== "undefined" ? disableDate : ["2000-00-01"];
        this.calendarHandler = Flatpickr("#calendar", {
            inline: true,
            locale: this.setLocale() ? this.setLocale() : "en",
            disable: disDates,
            dateFormat: "Y-m-d",
            onReady: function (selectedDates, dateStr, instance) {
                adjustSelectWidth(instance.monthsDropdownContainer);
                calendar.windowsCalendarHoverAnimation();
                const date = document.querySelector("#date");
                if (!date) {
                    return;
                }

                date.value = "";
            },
            onDayCreate: (dates, currentDateString, self, data) => {
                const date = data.dateObj;
                const dateString = [
                    date.getFullYear().toString().padStart(4, "0000"),
                    (date.getMonth() + 1).toString().padStart(2, "00"),
                    date.getDate().toString().padStart(2, "00"),
                ].join("-");

                if ((disabledDates2 || []).includes(dateString)) {
                    data.classList.add("flatpickr-disabled2");
                }
            },
            onMonthChange: function (selectedDates, dateStr, instance) {
                adjustSelectWidth(instance.monthsDropdownContainer);
            },
            onChange: function (selectedDates, dateStr, instance) {
                adjustSelectWidth(instance.monthsDropdownContainer);

                const date = document.querySelector("#date");
                if (!date) {
                    return;
                }

                date.value = dateStr;

                const dateErrElement = document.querySelector(".date-error");
                if (
                    dateStr &&
                    dateErrElement &&
                    !dateErrElement.classList.contains("hidden")
                ) {
                    dateErrElement.classList.add("hidden");
                }
            },
        });
    },

    init() {
        this.calendar();
    },
};
document.addEventListener("DOMContentLoaded", function () {
    calendar.init();
});

document.addEventListener("DOMContentLoaded", function () {
    const formpageWrapper = document.querySelector(".layout.form-page");

    let back = document.querySelector(".calendar-icon"); // temp

    if (!formpageWrapper) return;

    //forvard
    window.nextPage = function () {
        pl();
    };
    //back
    back.addEventListener("click", function () {
        bapl();
    });

    function setElementToStartPosition() {
        if (window.matchMedia("(min-width: 1024px)").matches) {
            gsap.set(".overlay", { xPercent: -100, opacity: 0 });
            gsap.set(".calendar-icon", { yPercent: -100, opacity: 0 });
            gsap.set(".calendar-text", { y: 30, opacity: 0 });
            gsap.set(".form-step2 .full-wrapper__inner", {
                y: 30,
                opacity: 0,
                display: "none",
            });
            gsap.set(".form-step2", { opacity: 0, pointerEvents: 'none' });
        } else {
            gsap.set(".form-step2", { opacity: 0, pointerEvents: 'none' });
            gsap.set(".overlay", { xPercent: -100, opacity: 0 });
            gsap.set(
                ".form-step2 .calendar-text, .form-step2 .flatpickr-calendar",
                {
                    opacity: 0,
                    xPercent: 10,
                }
            );
        }
    }
    setElementToStartPosition();

    const tls = gsap
        .timeline({
            paused: true,
            defaults: {
                duration: 0.4,
            },
            onReverseComplete: function () {
                document
                    .querySelector(".header")
                    .classList.add("header_white-right");
                document.querySelector(".layout").classList.remove("step2");
            },
            onStart: function () {
                document
                    .querySelector(".header")
                    .classList.remove("header_white-right");
                document.querySelector(".layout").classList.add("step2");
            },
        })
        .timeScale(1.2);

    if (window.matchMedia("(min-width: 1024px)").matches) {
        tls.to(".full-wrapper__part_left .full-wrapper__inner, .form-step1", {
            yPercent: 5,
            opacity: 0,
            display: "none",
            stagger: 0.15,
        })
            .to(
                ".form-page .full-wrapper__part_left",
                {
                    duration: 0.7,
                    flexBasis: 0,
                },
                ">-.2"
            )
            .to(
                ".form-page .full-wrapper__part_right",
                {
                    backgroundColor: "#ffffff",
                },
                "<"
            )

            .to(".form-step2", { opacity: 1, duration: 0.2, pointerEvents: 'auto' }, "<.35")

            .to(
                ".overlay",
                {
                    xPercent: 0,
                    opacity: 1,
                },
                "<.2"
            )
            .to(
                ".calendar-icon",
                {
                    yPercent: 0,
                    opacity: 1,
                },
                "<"
            )
            .to(
                ".calendar-text",
                {
                    y: 0,
                    opacity: 1,
                },
                "<"
            )
            .to(
                ".form-step2 .full-wrapper__inner",
                {
                    y: 0,
                    opacity: 1,
                    display: "flex",
                },
                "<"
            );
    } else {
        tls.to(".full-wrapper__part_left .full-wrapper__inner, .form-step1", {
            keyframes: [
                {
                    yPercent: 10,
                    opacity: 0,
                    stagger: 0.15,
                },
                {
                    duration: 0,
                    display: "none",
                },
            ],
        })
            .to(
                ".form-step1 .form__submit",
                {
                    opacity: 0,
                    duration: 0.2,
                },
                "<"
            )
            .to(
                ".form-page .full-wrapper__part_right",
                {
                    backgroundColor: "#ffffff",
                },
                "<"
            )
            .to(".form-step2", {
                opacity: 1,
                duration: 0.2,
                pointerEvents: 'auto',
                position: "relative",
            })
            .to(
                ".overlay",
                {
                    xPercent: 0,
                    opacity: 1,
                },
                "<.2"
            )
            .to(".form-step2 .calendar-text, .form-step2 .flatpickr-calendar", {
                opacity: 1,
                xPercent: 0,
                stagger: 0.15,
            });

        const footer = document.getElementsByClassName("footer");
        if (!!footer) {
            tls.to(
                footer,
                {
                    opacity: 0,
                    yPercent: 10,
                },
                "-=.5s"
            );
            setTimeout(() => {
                tls.to(footer, {
                    opacity: 1,
                    yPercent: 0,
                });
            }, 2000);
        }
    }

    function pl() {
        tls.play();
    }
    function bapl() {
        tls.reverse();
    }
});
