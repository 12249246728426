// import $ from "jquery";
// import './components/jquery.fullpage.extensions.min';
// import slick from 'slick-carousel';
// import './components/pixi.js';
// import {TimelineMax, Power3, TweenMax} from 'gsap';
// import './components/sliderDisp.js';
// import { TimelineMax } from 'gsap';
// import './components/formComponent';
import './components/selectMultiline';
import './components/calendar';
import textFit from 'textfit';



// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

const mainWrap = document.querySelector('main');


if(mainWrap.classList.contains('particular-page')){
    // Input code onFocus
    const wrapper = document.querySelector('.row__business'),
            code     = document.getElementById('input-code'),
            errorCode = document.querySelector('.error-input'),
            textFitElems = document.querySelectorAll('.js-textfit'),
            btnCode   = document.getElementById('submit-code');

    // btnCode.setAttribute('disabled', true);

    const onFocusCode = () => {

        wrapper.addEventListener('click', function(){
            // document.body.classList.add('code-focus');
            code.focus();
        })


        // code.onfocus = () => {
        //     document.body.classList.add('code-focus');
        // };
        // code.onblur = () => {
        //     document.body.classList.remove('code-focus');
        //     code.removeAttribute('style');
        // };
    };
    if(!mainWrap.classList.contains('business-page')){
        onFocusCode();
    }

    // Input code check
    const checkCode = () => {
        code.oninput = () =>{
            if(code.value !== ''){
                // btnCode.removeAttribute('disabled');
                code.removeAttribute('style');
            } else {
                code.style.borderColor = 'red';
                // btnCode.setAttribute('disabled', true);
            }
        };
    };
    checkCode();



    function titlesFit(){
        if(textFitElems.length > 0){


            setTimeout(() => {
                textFitElems.forEach( elem => {
                    textFit(elem);
                })


                let mustEqualFzElemExist = document.querySelector('.js-mustEqualFz') !== null;
                if(mustEqualFzElemExist){
                    let
                        fzTextFitElem = document.querySelector('.js-textfit .textFitted').style.fontSize,
                        mustEqualFzElem = document.querySelector('.js-mustEqualFz');


                    mustEqualFzElem.style.fontSize = fzTextFitElem;


                    textFitElems[0].classList.add('is-visible');
                    mustEqualFzElem.classList.add('is-visible');
                } else {
                    textFitElems.forEach( elem => {
                        elem.classList.add('is-visible');
                    })
                }
            }, 100);
        }
    }

    document.addEventListener("DOMContentLoaded", titlesFit);
    window.addEventListener("orientationchange", titlesFit);


}


function toggle(selector,cls){
    let target = document.querySelectorAll(selector);
    if(target){
        target.forEach(text => {
            text.addEventListener('click', function(){
                this.classList.toggle(cls);
            })
        })
    }
}

// toggle('.js-accordion-text','is-open');
toggle('.header__trigger','is-open');



(function closeMobLang(){
    let target = document.querySelector('.header__lang ul'),
        trigger = document.querySelector('.header__trigger');

    if(window.matchMedia('(max-width: 1024px)').matches && !!trigger){
        target.addEventListener('click', function(){
            trigger.classList.remove('is-open');
        })
        document.onclick = function(e){
            let
                isClickInside = target.contains(e.target),
                isClickInside2 = trigger.contains(e.target);
            if (!isClickInside && !isClickInside2 ){
                trigger.classList.remove('is-open');
            };
        };
    }
})()


function _getAbsoluteHeight(el) {
    el = (typeof el === 'string') ? document.querySelector(el) : el;
    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
                 parseFloat(styles['marginBottom']);
    return Math.ceil(el.offsetHeight + margin);
}

//footer height variable
function setVariableFooterHeight(){
    setTimeout(() => {
        const footerH = _getAbsoluteHeight('.footer');

        if(footerH !== 0){
            document.documentElement.style.setProperty('--footerHeight', footerH + 'px');
        }

    }, 200);
}

setVariableFooterHeight();

window.addEventListener("orientationchange", function() {
    setVariableFooterHeight();
});