import Choices from "choices.js";

const newStoreId = "1c635783-0986-4bc4-ba41-84e9dc6616f9";

function callbackOnCreateTemplates(strToEl) {
    var classNames = this.config.classNames;
    var itemSelectText = this.config.itemSelectText;
    return {
        item: function (classNames, data) {
            return strToEl(
                '\
            <div\
              class="' +
                    String(classNames.item) +
                    " " +
                    String(
                        data.highlighted
                            ? classNames.highlightedState
                            : classNames.itemSelectable
                    ) +
                    '"\
              data-item\
              data-id="' +
                    String(data.id) +
                    '"\
              data-value="' +
                    String(data.value) +
                    '"\
              ' +
                    String(data.active ? 'aria-selected="true"' : "") +
                    "\
              " +
                    String(data.disabled ? 'aria-disabled="true"' : "") +
                    '\
              >\
              <div class="choices-custom__line"> <div class="choices-custom__address' +
                    (data.customProperties.address === null ? " hidden" : "") +
                    '">' +
                    String(data.customProperties.address) +
                    '</div> <div class="choices-custom__shop">' +
                    String(data.label) +
                    "</div></div> \
            </div>\
          "
            );
        },
        choice: function (classNames, data) {
            return strToEl(
                '\
            <div\
              class="' +
                    String(classNames.item) +
                    " " +
                    String(classNames.itemChoice) +
                    " " +
                    String(
                        data.disabled
                            ? classNames.itemDisabled
                            : classNames.itemSelectable
                    ) +
                    '"\
              data-select-text="' +
                    String(itemSelectText) +
                    '"\
              data-choice \
              ' +
                    String(
                        data.disabled
                            ? 'data-choice-disabled aria-disabled="true"'
                            : "data-choice-selectable"
                    ) +
                    '\
              data-id="' +
                    String(data.id) +
                    '"\
              data-value="' +
                    String(data.value) +
                    '"\
              ' +
                    String(
                        data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                    ) +
                    '\
              >\
              <div class="choices-custom__line"> <div class="choices-custom__address' +
                    (data.customProperties.address === null ? " hidden" : "") +
                    '">' +
                    String(data.customProperties.address) +
                    '</div> <div class="choices-custom__shop">' +
                    String(data.label) +
                    "</div></div> \
            </div>\
          "
            );
        },
    };
}

document.addEventListener("DOMContentLoaded", function () {
    let select1 = document.getElementById("store_id");
    let select2 = document.getElementById("shopSelect");

    // if business
    if (typeof storesLine !== "undefined" && select1) {
        let select1Worker = new Choices(select1, {
            callbackOnCreateTemplates,
            shouldSort: false,
        });

        const select1Array = [
            ...storesList.map((item) => {
                return {
                    value: item.value,
                    label: item.name,
                    customProperties: {
                        address: !!item.full_address ? item.full_address : null,
                    },
                };
            }),
            {
                value: "",
                label:
                    typeof shopPlaceholder !== "undefined"
                        ? shopPlaceholder
                        : "Placeholder",
                selected: true,
                disabled: true,
                customProperties: {
                    address: null,
                },
            },
        ];
        select1Worker.setChoices(select1Array, "value", "label", false);

        select1Worker.passedElement.element.addEventListener(
            "addItem",
            function (event) {
                const formStep1 = document.querySelector(".form-step1");
                if (formStep1) {
                    formStep1.classList.remove("store-is-not-selected");
                }

                document
                    .getElementById("js-business-form-inner")
                    .classList.remove("invisible");

                document.body.classList.add("bottom-btn-is-active");

                const inputsToReset = document.querySelectorAll(
                    ".reset-on-change-store"
                );
                inputsToReset.forEach((inputToReset) => {
                    inputToReset.value = null;
                    inputToReset.parentNode.classList.remove(
                        "form__field_active"
                    );
                });

                if (event.detail.value !== newStoreId) {
                    storesLine.forEach((store) => {
                        if (store.id === event.detail.value) {
                            // console.log(store);
                            for (let fieldName in store) {
                                let elem = document.getElementById(fieldName);
                                if (elem && store.hasOwnProperty(fieldName)) {
                                    elem.value = store[fieldName];
                                    elem.parentNode.classList.add(
                                        "form__field_active"
                                    );
                                }
                            }
                        }
                    });
                    formComponent.modelValidation(false);
                    document.getElementById("store_name").dataset.validate = "";
                    document
                        .getElementById("store_name")
                        .classList.remove("input-error");
                    document
                        .querySelector("#store_name ~ .error")
                        .classList.add("hidden");
                    document
                        .getElementById("store-text-wrapper")
                        .classList.add("hidden");
                } else {
                    // select.closest('.form__line').classList.add('hidden');
                    document.getElementById("store_name").dataset.validate =
                        "no-empty";
                    document
                        .getElementById("store-text-wrapper")
                        .classList.remove("hidden");
                }

                // console.log(event.detail.id);
            },
            false
        );
    }

    //if client
    if (typeof shopLine !== "undefined" && select2) {
        let select2Worker = new Choices(select2, {
            callbackOnCreateTemplates,
        });

        let select2Array = [
            {
                value: "",
                label:
                    typeof shopClientPlaceholder !== "undefined"
                        ? shopClientPlaceholder
                        : "Placeholder",
                selected: true,
                disabled: true,
                customProperties: {
                    address: null,
                },
            },
        ];

        shopLine.map((item) => {
            select2Array.push({
                value: item.shop,
                label: item.shop,
                customProperties: {
                    address: item.full_address,
                },
            });
        });
        select2Worker.setChoices(select2Array, "value", "label", false);
    }
});

// customTemplates.passedElement.element.addEventListener(
//     'addItem',
//     function(event) {
//         // do something creative here...
//         console.log(event.detail.id);
//         console.log(event.detail.value);
//         console.log(event.detail.label);
//         console.log(event.detail.customProperties);
//         console.log(event.detail.groupValue);
//       },
//       false,
// );
